import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter as Router } from 'connected-react-router'
import { defaultHistory, persistor, store } from 'store'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import App from 'App'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={defaultHistory} noInitialPop>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
