import React from 'react'
import { StyleSheet, css } from 'styles'
import { useClock } from 'effects/useClock'

type BinaryBackgroundProps = { number: string }
type DrawBackgroundProps = { value: string }

const BinaryBackground = ({ number = '0' }: BinaryBackgroundProps) => {
  const numberValue = number.toString()
  const units = numberValue.split('')

  const DrawBackground = ({ value }: DrawBackgroundProps) => {
    return <div className={`${css(styles.background)} ${+value ? css(styles.bgOn) : css(styles.bgOff)}`} />
  }

  return <div className="d-flex">
    {units.map((unit, key) => {
      const binaries = parseInt(unit).toString(2).padStart(4, '0').split('')
      return <div key={key}>
        {binaries.map((binary, index) => <DrawBackground key={index} value={binary} />)}
      </div>
    })}
  </div>
}

const getTimeWithTwoDigits = (time: number): string => time.toString().padStart(2, '0')

export const Timer = () => {
  const time = useClock()
  const hour = getTimeWithTwoDigits(time.getHours())
  const minutes = getTimeWithTwoDigits(time.getMinutes())
  const seconds = getTimeWithTwoDigits(time.getSeconds())
  const milliseconds = Math.floor(time.getMilliseconds() / 100).toString()
  return <div className="position-relative">
    <div className={css(styles.timer)}>{hour}:{minutes}:{seconds}:{milliseconds}</div>
    <div className="d-flex">
      <BinaryBackground number={hour} />
      <BinaryBackground number={minutes} />
      <BinaryBackground number={seconds} />
      <BinaryBackground number={milliseconds} />
    </div>
  </div>
}

const styles = StyleSheet.create({
  background: {
    border: '1px solid #003434',
    opacity: 0.4,
    height: 16,
    width: 60
  },
  bgOn: {
    backgroundColor: '#00c3c3',
  },
  bgOff: {
    backgroundColor: '#008787',
  },
  timer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000
  }
})
