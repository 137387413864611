import { Credentials, Token, UserInfos } from 'types'

export const RECEIVE_LOGIN_TOKEN = 'RECEIVE_LOGIN_TOKEN'
export const receiveLoginToken = (token: Token) => ({ type: RECEIVE_LOGIN_TOKEN, payload: { token } })

export const RECEIVE_LOGIN_USER = 'RECEIVE_LOGIN_USER'
export const receiveLoginUser = (userInfos: UserInfos) => ({ type: RECEIVE_LOGIN_USER, payload: { userInfos } })

export const RECEIVE_LOGOUT_USER = 'RECEIVE_LOGOUT_USER'
export const receiveLogoutUser = () => ({ type: RECEIVE_LOGOUT_USER })

export const REQUEST_LOGIN_USER = 'REQUEST_LOGIN_USER'
export const requestLoginUser = (credentials: Credentials) => ({ type: REQUEST_LOGIN_USER, payload: { credentials } })

export const REQUEST_LOGOUT_USER = 'REQUEST_LOGOUT_USER'
export const requestLogoutUser = () => ({ type: REQUEST_LOGOUT_USER })

