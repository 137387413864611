import { all, put, takeLatest } from 'redux-saga/effects'
import { userService } from 'services/userService'
import * as userActions from 'users/state/actions'

function* fetchUsers() {
  try {
    const users = yield userService.getUsers()
    yield put(userActions.receiveUsers(users))
  } catch (exception) {
    // Error needed with core
    console.log(exception)
  }
}

export const usersSaga = function* usersSaga() {
  all([
    yield takeLatest(userActions.REQUEST_USERS, fetchUsers)
  ])
}
