import { useEffect, useState } from 'react'

export const useClock = (initialTime = new Date()): Date => {
  const [time, setTime] = useState(initialTime)
  useEffect(() => {
    const id = setInterval(() => {
      setTime(new Date())
    }, 100)
    return () => clearInterval(id)
  }, [])
  return time
}
