import * as userActions from 'users/state/actions'
import { AnyAction } from 'redux'

const initialState = {
  isFetching: false,
  users: []
}

export const userReducer = (currentState = initialState, action: AnyAction) => {
  let newState = { ...currentState }
  switch (action.type) {
    case userActions.REQUEST_USERS:
      newState.isFetching = true
      break
    case userActions.RECEIVE_USERS:
      newState.isFetching = false
      newState.users = action.payload?.users ?? newState.users
      break
    default:
  }
  return newState
}
