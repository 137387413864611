import { all, put, takeLatest } from 'redux-saga/effects'
import { authService } from 'services'
import * as coreActions from 'core/state/actions'
import { AnyAction } from 'redux'

function* loginUser(action: AnyAction) {
  try {
    const token = yield authService.connectUser(action.payload.credentials)
    yield put(coreActions.receiveLoginToken(token))
    const userInfos = yield authService.getConnected()
    yield put(coreActions.receiveLoginUser(userInfos))
  } catch (exception) {
    // Error needed with core
    console.log(exception)
  }
}

function* logoutUser() {
  try {
    yield authService.disconnectUser()
    yield put(coreActions.receiveLogoutUser())
  } catch (exception) {
    // Error needed with core
    console.log(exception)
  }
}

export const coreSaga = function* coreSaga() {
  all([
    yield takeLatest(coreActions.REQUEST_LOGIN_USER, loginUser),
    yield takeLatest(coreActions.REQUEST_LOGOUT_USER, logoutUser),
  ])
}
