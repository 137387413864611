import { api } from 'core/api'

interface Credentials {
  username: string
  password: string
}

export const authService = {
  connectUser: async (credentials: Credentials) => {
    try {
      const response = await api({
        method: 'post',
        url: 'auth/login',
        data: credentials
      })
      return response.data
    } catch (exception) {
      throw exception
    }
  },

  disconnectUser: async() => {
    try {
      const response = await api({
        method: 'post',
        url: 'auth/logout'
      })
      return response.data
    } catch (exception) {
      throw exception
    }
  },

  getConnected: async() => {
    try {
      const response = await api.get('auth/user')
      return response.data?.data
    } catch (exception) {
      throw exception
    }
  },
}