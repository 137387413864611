import { createBrowserHistory, History } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { responsiveStoreEnhancer } from 'redux-responsive'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { all } from 'redux-saga/effects'
import { rootReducer } from 'store/rootReducer'
import { coreSaga } from 'core/state/sagas'
import { usersSaga } from 'users/state/sagas'

const persistConfig = {
  key: 'root',
  storage
}
const persistedReducer = (history: History) => persistReducer(persistConfig, rootReducer(history))

export const buildStore = (history: History) => {
  const sagaMiddleware = createSagaMiddleware()
  const createdStore = createStore(
    persistedReducer(history),
    composeWithDevTools(
      responsiveStoreEnhancer,
      applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )
  )
  sagaMiddleware.run(function*() {
    yield all([
      coreSaga(),
      usersSaga()
    ])
  })
  return createdStore
}

export const defaultHistory = createBrowserHistory()
export const store = buildStore(defaultHistory)
export const persistor = persistStore(store)
