import { api } from 'core/api'

export const userService = {
  getUsers: async () => {
    try {
      const users = await api.get('users')
      return users.data
    } catch (exception) {
      throw exception
    }
  }
}