import React from 'react'
import { LoginForm } from 'login/components/LoginForm'
import troisdeuxun from 'troisdeuxun.svg'
import { MDBContainer, MDBRow } from 'mdbreact'
import { css, StyleSheet } from 'styles'

const morgesImage = process.env.PUBLIC_URL + 'morges.jpeg'
export const LoginPage = () => {
  return <div className={css(styles.background)}>
    <MDBContainer>
      <MDBRow center>
        <div className="card my-5">
          <div className="card-body">
            <h5 className="card-title">Connexion</h5>
            <LoginForm />
          </div>
        </div>
      </MDBRow>
      <MDBRow center className="my-5 py-5">
        <img src={troisdeuxun} className={css(styles.logo)} alt="TroisDeuxUn" />
      </MDBRow>
    </MDBContainer>
  </div>
}

const backgroundImage = `linear-gradient(black, black), url(${morgesImage})`
const styles = StyleSheet.create({
  background: {
    height: '100%',
    backgroundImage,
    backgroundBlendMode: 'soft-light',
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  logo: {
    height: "20vmin"
  }
})