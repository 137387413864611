import React from 'react'
import { Store } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { getLoggedStatus } from 'core/state/selectors'
import { Dashboard } from 'dashboard/Dashboard'
import { LoginPage } from 'login/LoginPage'
import { NotFound } from 'routes/NotFound'
import { UsersPage } from 'users/UsersPage'

type Props = {
  isLoggedIn: boolean
}

export const UnconnectedRoutes = ({ isLoggedIn }: Props) => {
  return isLoggedIn
    ? <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route exact path="/users">
        <UsersPage />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
    : <Switch>
      <Route><LoginPage /></Route>
    </Switch>
}

const mapStateToProps = (state: Store) => ({
  isLoggedIn: getLoggedStatus(state)
})

export const Routes = connect(mapStateToProps)(UnconnectedRoutes)
