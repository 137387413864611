import React from 'react'
import { NavBar } from 'navigation/components/NavBar'
import { Routes } from 'routes/Routes'
import 'styles/App.css'

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes />
    </div>
  )
}

export default App
