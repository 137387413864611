import React, { useState } from "react";
import { connect } from 'react-redux'
import { Dispatch, Store } from 'redux'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBDropdown,
  MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "mdbreact";
import { Link } from 'react-router-dom'
import { requestLogoutUser } from 'core/state/actions'
import { getLoggedStatus, getUserInfos } from 'core/state/selectors'
import { getCurrentRoute } from 'navigation/state/selectors'
import { UserInfos } from 'types'

type Props = {
  currentRoute: string
  isLoggedIn: boolean
  logout: Function
  userInfos: UserInfos
}

export const UnconnectedNavBar = ({ currentRoute, isLoggedIn, logout, userInfos }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return isLoggedIn ? <MDBNavbar color="default-color" dark expand="md">
    <Link to="/">
      <MDBNavbarBrand>
        <strong className="white-text">321</strong>
      </MDBNavbarBrand>
    </Link>
    <MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
    <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
      <MDBNavbarNav left>
        <MDBNavItem active={currentRoute === '/users'}>
          <Link to="/users">Utilisateurs</Link>
        </MDBNavItem>
      </MDBNavbarNav>
      <MDBNavbarNav right>
        <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle nav caret>
              <MDBIcon icon="user" /> {userInfos.name}
            </MDBDropdownToggle>
            <MDBDropdownMenu className="dropdown-default" right>
              <MDBDropdownItem onClick={() => logout()} href="#!"><MDBIcon icon="door-open" className="mr-2" />Se déconnecter</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar> : null
}

const mapStateToProps = (state: Store) => ({
  currentRoute: getCurrentRoute(state),
  isLoggedIn: getLoggedStatus(state),
  userInfos: getUserInfos(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(requestLogoutUser())
})

export const NavBar = connect(mapStateToProps, mapDispatchToProps)(UnconnectedNavBar)
