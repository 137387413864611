import * as coreActions from 'core/state/actions'
import { AnyAction } from 'redux'
import { api } from 'core/api'

const initialState = {
  isLoggedIn: false,
  token: {
    access_token: '',
    expires_in: 0,
    refresh_token: '',
    type: ''
  },
  userInfos: {
    email: '',
    name: ''
  }
}

export const coreReducer = (currentState = initialState, action: AnyAction) => {
  let newState = { ...currentState }
  switch (action.type) {
    case coreActions.RECEIVE_LOGIN_TOKEN:
      const token = action.payload?.token
      newState.token = token
      localStorage.setItem('access_token', token?.access_token)
      api.defaults.headers.Authorization = `Bearer ${token?.access_token}`
      newState.isLoggedIn = true
      break

    case coreActions.RECEIVE_LOGIN_USER:
      newState.userInfos = action.payload?.userInfos
      break

    case coreActions.RECEIVE_LOGOUT_USER:
      localStorage.removeItem('access_token')
      delete api.defaults.headers.Authorization
      newState.isLoggedIn = false
      break
    default:
  }
  return newState
}
