import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getUsers } from 'users/state/selectors'
import { requestUsers } from 'users/state/actions'
import { Dispatch, Store } from 'redux'
import { User } from 'types'

type Props = {
  updateUsers: Function,
  users: User[]
}

export const UnconnectedUsersPage = ({ updateUsers, users }: Props) => {
  useEffect(() => {
    updateUsers()
  }, [updateUsers])

  return <header className="App-header">
    <p>Users</p>
    {!!users.length && <ul className="list-group">
      {users.map((user, key) => <li className="list-group-item list-group-item-secondary text-left" key={key}>{user.email}</li>)}
    </ul>}
  </header>
}

const mapStateToProps = (state: Store) => ({
  users: getUsers(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUsers: () => dispatch(requestUsers())
})

export const UsersPage = connect(mapStateToProps, mapDispatchToProps)(UnconnectedUsersPage)
