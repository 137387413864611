import React from 'react'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import { StyleSheet, css } from 'styles'
import * as yup from 'yup'
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import * as coreActions from 'core/state/actions'
import { getUserInfos } from 'core/state/selectors'
import { Credentials } from 'types/credentials'
import { Dispatch, Store } from 'redux'

type Props = {
  loginUser: Function,
  userEmail: string
}

export const UnconnectedLoginForm = ({ loginUser, userEmail }: Props) => {
  const formik = useFormik({
    initialValues: {
      username: userEmail,
      password: ''
    },
    validationSchema,
    onSubmit: (values)  => loginUser(values)
  })

  return <div className={css(styles.container)}>
    <form onSubmit={formik.handleSubmit}>
      <div className="grey-text">
        <MDBInput label="Email" group type="email" error="wrong" success="right"
                  {...formik.getFieldProps('username')} />
        {formik.touched.username && formik.errors.username && <div>{formik.errors.username}</div>}
        <MDBInput label="Password" group type="password" error="wrong" success="right"
                  {...formik.getFieldProps('password')} />
        {formik.touched.password && formik.errors.password && <div className="has-error">{formik.errors.password}</div>}
      </div>
      <div className="text-center">
        <MDBBtn outline color="info" type="submit" disabled={formik.isSubmitting}>
          Se connecter
          <MDBIcon far icon="paper-plane" className="ml-1" />
        </MDBBtn>
      </div>
    </form>
  </div>
}

const styles = StyleSheet.create({
  container: {
    minWidth: 320
  }
})

const validationSchema = yup.object().shape({
  username: yup.string()
    .email('Email invalide')
    .required('Vous devez remplir ce champ'),
  password: yup.string()
    .min(6, "Trop court")
    .max(20, "Trop long")
    .required('Vous devez remplir ce champ')
})

const mapStateToProps = (state: Store) => ({
  userEmail: getUserInfos(state).email
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUser: (credentials: Credentials) => dispatch(coreActions.requestLoginUser(credentials))
})

export const LoginForm = connect(mapStateToProps, mapDispatchToProps)(UnconnectedLoginForm)
