import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { responsiveStateReducer } from 'redux-responsive'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { coreReducer } from 'core/state/reducer'
import { userReducer } from 'users/state/reducer'

export const rootReducer = (history: History) => combineReducers({
  browser: responsiveStateReducer,
  core: persistReducer({key: 'core', storage}, coreReducer),
  router: persistReducer({key: 'router', storage}, connectRouter(history)),
  users: persistReducer({key: 'users', storage}, userReducer)
})
